<template>
  <v-menu
    bottom
    transition="slide-y-transition"
    nudge-left="20"
    rounded="70"
    offset-y
    min-width="200"
    :close-on-content-click="true">
    <template
      #activator="{ on }">
      <v-icon
        size="18"
        color="darkGrey"
        v-on="on">
        mdi-filter
      </v-icon>
    </template>
    <v-card>
      <v-list>
        <v-list-item
          v-for="(tag, index) in tagsList"
          :key="index"
          :class="{ 'v-list-item--active': activeTags[col] && activeTags[col].includes(tag) }"
          @click="updateActiveTags(tag)">
          <v-list-item-title>{{ tag }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-btn
        v-if="activeTags[col] && activeTags[col].length"
        class="mt-3"
        color="blue"
        block
        outlined
        @click="clearFilter">
        Clear filter
      </v-btn>
    </v-card>
  </v-menu>
</template>
<script>
import {
  mapState, mapMutations, mapActions, mapGetters,
} from 'vuex';
import { RECALCULATE_TAG_TOTAL_COST } from '@/constants/userPermissions';
export default {
  name: 'FilterMenu',
  props: {
    col: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tags: [],
    };
  },
  computed: {
    ...mapState('ProjectDetailsTableSchedule', ['scheduleId']),
    ...mapState('ScheduleViews', ['activeTags']),
    ...mapGetters('ScheduleViews', ['selectedView']),
    ...mapGetters('ScheduleRows', ['scheduleTagsList']),
    ...mapState('ScheduleRows', ['viewInfo']),
    tagsList() {
      return this.scheduleTagsList(this.col);
    },
  },
  methods: {
    ...mapMutations({
      spinner: 'spinner',
      setTagTotal: 'ProjectAggregation/setTagTotal',
      setActiveTags: 'ScheduleViews/setActiveTags',
    }),
    ...mapActions({
      setViewsSorting: 'ScheduleViews/setViewsSorting',
      getTotalOfTag: 'ProjectAggregation/getTotalOfTag',
      removeSubscriptions: 'ManageSubscriptions/removeSubscriptions',
    }),
    async updateActiveTags(tag) {
      let activeTags = this.activeTags[this.col] || [];
      if (activeTags.includes(tag)) {
        activeTags = activeTags.filter(t => t != tag);
        this.setActiveTags({
          ...this.activeTags,
          [this.col]: activeTags.length ? activeTags : undefined,
        });
      } else {
        this.setActiveTags({
          ...this.activeTags,
          [this.col]: [...activeTags, tag],
        });
      }
      this.totalCostOfTag(tag);
    },
    async totalCostOfTag(tagName) {
      try {
        await this.getTotalOfTag({
          tableId: this.scheduleId,
          tableType: 'schedule',
          tagName,
        });
      } catch (err) {
        console.log(err);
      }
    },
    clearFilter() {
      this.setActiveTags({
      });
      this.setTagTotal(null);
      this.removeSubscriptions(RECALCULATE_TAG_TOTAL_COST);
    },
  },
};
</script>
<style lang="scss" scoped>
  .v-icon {
    cursor: pointer;
  }
  .content {
    padding: 20px;
    display: grid;
    gap: 20px;
  }
  .tags {
    display: grid;
    gap: 5px;
    height: 115px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.356);
    overflow: auto;
  }
  .buttons {
    display: grid;
    gap: 10px;
  }
  .v-input--selection-controls {
    margin: 0;
    padding: 0;
  }
</style>
