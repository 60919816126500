<template>
  <div>
    <v-text-field
      v-model="customFieldsFilter"
      placeholder="Search custom fields..."
      class="mb-6 ml-3" />

    <v-list>
      <v-list-item
        v-for="(customField, index) in customFields"
        :key="index">
        <v-list-item-title>
          {{ customField }}
        </v-list-item-title>
        <v-list-item-action class="flex-row gap-3">
          <v-icon
            class="cursor-pointer"
            color="black"
            @click="editProjectColumn(customField)">
            mdi-pencil
          </v-icon>
          <v-icon
            class="cursor-pointer"
            color="black"
            @click="openDeleteProjectColumnDialog(customField)">
            mdi-delete
          </v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <!-- Edit Custom Field Dialog -->
    <v-dialog
      v-model="isEditCustomFieldDialogOpen"
      max-width="500">
      <v-card>
        <v-card-title>
          Edit Custom Field
          <v-spacer />
          <span
            class="sourcery__icon-wrapper black"
            @click="isEditCustomFieldDialogOpen = false">
            <v-icon
              color="white"
              size="15">
              mdi-close
            </v-icon>
          </span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="selectedCustomField"
            label="Custom Field" />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            elevation="0"
            outlined
            @click="isEditCustomFieldDialogOpen = false">
            Cancel
          </v-btn>
          <v-btn
            :disabled="!selectedCustomField"
            color="blue"
            class="white--text"
            elevation="0"
            @click="renameProjectColumn">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Custom Field Dialog -->
    <v-dialog
      v-model="isDeleteCustomFieldDialogOpen"
      max-width="500">
      <v-card>
        <v-card-title>
          Delete Custom Field
          <v-spacer />
          <span
            class="sourcery__icon-wrapper black"
            @click="isDeleteCustomFieldDialogOpen = false">
            <v-icon
              color="white"
              size="15">
              mdi-close
            </v-icon>
          </span>
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete the custom field {{ selectedCustomField }}?
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            outlined
            @click="isDeleteCustomFieldDialogOpen = false">
            Cancel
          </v-btn>
          <v-btn
            color="blue"
            class="white--text"
            elevation="0"
            @click="deleteProjectColumn">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapActions,
  mapState,
} from 'vuex';

export default {
  name: 'CustomFieldsManager',
  data() {
    return {
      customFieldsFilter: '',
      isDeleteCustomFieldDialogOpen: false,
      isEditCustomFieldDialogOpen: false,
      selectedCustomField: '',
      selectedCustomFieldOldName: '',
    };
  },
  computed: {
    ...mapState('ProjectDetailsTableSchedule', ['customFields']),
    projectId() {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions('ProjectDetailsTableSchedule', ['deleteProjectColumnAction', 'renameProjectColumnAction']),
    editProjectColumn(customField) {
      this.selectedCustomField = customField;
      this.selectedCustomFieldOldName = customField;
      this.isEditCustomFieldDialogOpen = true;
    },
    deleteProjectColumn() {
      this.deleteProjectColumnAction({
        customField: this.selectedCustomField,
        projectId: this.projectId,
      });
      this.isDeleteCustomFieldDialogOpen = false;
    },
    openDeleteProjectColumnDialog(customField) {
      this.selectedCustomField = customField;
      this.isDeleteCustomFieldDialogOpen = true;
    },
    renameProjectColumn() {
      if (this.selectedCustomField !== this.selectedCustomFieldOldName) {
        this.renameProjectColumnAction({
          columnName: this.selectedCustomFieldOldName,
          newColumnName: this.selectedCustomField,
          projectId: this.projectId,
        });
      }
      this.isEditCustomFieldDialogOpen = false;
    },
  },
};
</script>
