var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{staticClass:"table-header-item",class:[
    { 'v-data-table-header__draggable': _vm.index > 2 && !_vm.isFocusedOnActions },
    _vm.isActiveResizable && 'resizable-element__header',
    _vm.thClass
  ],style:(_vm.headerName != 'order' && {
    width: (_vm.headerSize + "px"),
  }),attrs:{"data-ref":(_vm.headerName + "__ref"),"data-index":_vm.index,"role":"columnheader","scope":"col","aria-label":""},on:{"click":_vm.onSort,"mouseleave":function($event){return _vm.onMouseLeave(_vm.headerName)},"mouseover":function($event){return _vm.onMouseOver(_vm.headerName)}}},[_c('div',{class:[
      'v-data-table-header__wrapper',
      {
        'v-data-table-header__wrapper--custom-column': _vm.headerName === 'order',
      } ],style:({'margin-left': _vm.renderedDraggableRef ? '0' : '-16px'})},[(_vm.isAllowedToResize)?_c('vue-draggable-resizable',{staticClass:"d-flex align-center resizable-element",class:{
        'table-container__header-cell': _vm.activeCellHeader === _vm.headerName,
        'table-container__header-cell--hover': _vm.getActiveCellsNames.includes(_vm.headerName),
        'table-container__header-cell--hover-active': _vm.getActiveCellsNames.includes(_vm.headerName) && _vm.activeCellHeader === _vm.headerName,
      },attrs:{"class-name-handle":"resizable-handle","w":_vm.headerSize,"h":48,"min-width":_vm.headerMinSize,"axis":'x',"handles":['mr'],"on-resize":_vm.onResizeCallback,"resizable":_vm.canWorkWithResizeColumn && _vm.isResizable && !_vm.disabledEditMode,"active":_vm.isResizable,"draggable":false},on:{"resizing":function($event){return _vm.onResizeColumn({ width: arguments[2], colName: _vm.headerName, name: _vm.headerName, })},"resizestop":function($event){return _vm.onResizeColumnStop(
        { width: arguments[2],
          colId: _vm.columnId, resourceType: 'schedule',
          projectId: _vm.projectId, resourceId: _vm.scheduleId, viewId: _vm.selectedView.id, colName: _vm.headerName, isCustomSortable: _vm.isCustomSortable})}},scopedSlots:_vm._u([{key:"mr",fn:function(){return [_c('div',{staticClass:"resizable-handle-element",on:{"mouseover":function($event){$event.stopPropagation();_vm.isCanceledResizable ? _vm.setActiveColumn(_vm.headerName): null},"mouseleave":function($event){$event.stopPropagation();return _vm.setHoveredColumn(_vm.isCanceledResizable ? { name: _vm.headerName } : { })}}},[_c('div',{class:{ 'resizable-handle-element__selected-border': _vm.isActiveResizable }}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActiveResizable),expression:"isActiveResizable"}],staticClass:"resizable-handle-element__selected-border__bottom",style:({height: ((_vm.tableHeight - 48) + "px")})})])]},proxy:true}],null,false,1064998554)},[(_vm.isTagOrCustomColumn)?[_c('TableFilterTags',{attrs:{"is-free-workspace":_vm.isFreeWorkspace,"value":_vm.headerName}}),(_vm.isCustomSortable && !_vm.renderedDraggableRef)?_c('app-sort-arrows',{attrs:{"sort-by":_vm.headerName,"sorting-data":_vm.sortingData,"sortable-options":_vm.SORTABLE_OPTIONS},on:{"mouse-action":function($event){return _vm.$emit('focused-on-actions', true)},"mouse-action-end":function($event){return _vm.$emit('focused-on-actions', false)}}}):_vm._e()]:[_c('span',[_vm._v(" "+_vm._s(_vm.headerName)+" "),(_vm.isCustomSortable
              && !_vm.renderedDraggableRef)?_c('app-sort-arrows',{attrs:{"sort-by":_vm.headerName,"sorting-data":_vm.sortingData,"sortable-options":_vm.SORTABLE_OPTIONS},on:{"mouse-action":function($event){return _vm.$emit('focused-on-actions', true)},"mouse-action-end":function($event){return _vm.$emit('focused-on-actions', false)}}}):_vm._e()],1)]],2):(_vm.headerName!=='order')?[_c('div',{staticClass:"d-flex align-center table-header__not-resizable",class:{
          'table-container__header-cell': _vm.activeCellHeader === _vm.headerName,
          'table-container__header-cell--hover': _vm.getActiveCellsNames.includes(_vm.headerName),
          'table-container__header-cell--hover-active': _vm.getActiveCellsNames.includes(_vm.headerName) && _vm.activeCellHeader === _vm.headerName,
        }},[_c('span',[_vm._v(_vm._s(_vm.headerName))]),(_vm.isCustomSortable
            && !_vm.renderedDraggableRef)?_c('app-sort-arrows',{attrs:{"sort-by":_vm.headerName,"sorting-data":_vm.sortingData,"sortable-options":_vm.SORTABLE_OPTIONS},on:{"mouse-action":function($event){return _vm.$emit('focused-on-actions', true)},"mouse-action-end":function($event){return _vm.$emit('focused-on-actions', false)}}}):_vm._e()],1)]:(_vm.headerName === 'order')?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pointer",attrs:{"color":"green"},on:{"click":_vm.addNewColumn}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus-circle ")])]}}])},[_c('span',[_vm._v("Add New Field")])]),_c('v-dialog',{attrs:{"width":"40%","persistent":""},model:{value:(_vm.newColumnDialog),callback:function ($$v) {_vm.newColumnDialog=$$v},expression:"newColumnDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Add New Column")]),_c('span',{staticClass:"sourcery__icon-wrapper black"},[_c('v-icon',{attrs:{"color":"white","size":"14"},on:{"click":_vm.closeNewColumnDialog}},[_vm._v(" mdi-close ")])],1)]),_c('v-card-text',[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Column Name: ")]),_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"error-messages":_vm.errorMessage,"placeholder":"Color Emitance"},on:{"input":function($event){_vm.errorMessage = ''}},model:{value:(_vm.columnName),callback:function ($$v) {_vm.columnName=$$v},expression:"columnName"}})],1)],1),_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" Column Type: ")]),_c('v-col',{attrs:{"cols":"9"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.columnType),callback:function ($$v) {_vm.columnType=$$v},expression:"columnType"}},[_c('v-radio',{attrs:{"label":"Text","value":"string"}}),_c('v-radio',{attrs:{"label":"Dropdown","value":"dropdown"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"elevation-0",attrs:{"outlined":""},on:{"click":_vm.closeNewColumnDialog}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"elevation-0 white--text",attrs:{"disabled":!_vm.columnName,"color":"blue"},on:{"click":_vm.saveNewColumn}},[_vm._v(" Save ")])],1)],1)],1)]:_vm._e(),(_vm.isCustomSortable
        && !_vm.renderedDraggableRef
        && !(_vm.selectedViewTag && _vm.headerName == _vm.productHeaders.TAG))?_c('table-header-actions',{attrs:{"sort-by":_vm.headerName,"sorting-data":_vm.sortingData,"default-sort-by":_vm.selectedView.sortingField},on:{"sort":function($event){_vm.setViewsSorting({sortingData: _vm.sortHelper(_vm.selectedViewId, $event.sortBy, $event.sortOrder)})}}}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }