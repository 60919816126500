<template>
  <div>
    <span>{{ headerValue }} </span>
    <FilterMenu
      v-if="!disabledEditMode"
      :col="headerValue" />
    <SaveFilteredView
      v-if="hasFilteredTags"
      :view="customView" />
  </div>
</template>
<script>
import {
  mapState,
  mapGetters,
} from 'vuex';
import FilterMenu from './FilterMenu';
import SaveFilteredView from './SaveFilteredView';
export default {
  name: 'TableFilterTags',
  components: {
    FilterMenu,
    SaveFilteredView,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    header: {
      type: Object,
      default: null,
    },
    disabledEditMode: {
      type: Boolean,
      default: () => false,
    },
    isFreeWorkspace: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapState(['role']),
    ...mapState('ScheduleViews', ['activeTags']),
    ...mapGetters('ScheduleViews', ['selectedView']),
    headerValue() {
      return this.value || this.header?.value;
    },
    hasFilteredTags() {
      return this.activeTags && this.activeTags[this.headerValue]?.length > 0;
    },
    activeTagsStr() {
      return JSON.stringify(this.activeTags);
    },
    customView() {
      if (!this.hasFilteredTags) {
        return null;
      }
      return {
        name: this.selectedView.name + ' - ' + this.truncatedArrayString(),
        id: this.selectedView.id,
        filterTag: this.activeTagsStr,
      };
    },
  },
  methods: {
    truncatedArrayString(maxLength = 30) {
      let res = '[';
      let currLength = 1;
      for (let [index, tag] of this.activeTags[this.headerValue].entries()) {
        const remaining = maxLength - currLength;
        if (tag.length > remaining) {
          res += `'${tag.slice(0, remaining)}'` + '...';
        } else {
          res += `'${tag}'`;
          if (index < this.activeTags[this.headerValue].length - 1) {
            res += ', ';
          }
        }
      }
      res += ']';
      return res;
    },
  },
};
</script>
<style scoped lang="scss">
</style>
