<template>
  <th
    :class="[
      { 'v-data-table-header__draggable': index > 2 && !isFocusedOnActions },
      isActiveResizable && 'resizable-element__header',
      thClass
    ]"
    class="table-header-item"
    :data-ref="`${headerName}__ref`"
    :data-index="index"
    :style="headerName != 'order' && {
      width: `${headerSize}px`,
    }"
    role="columnheader"
    scope="col"
    aria-label
    @click="onSort"
    @mouseleave="onMouseLeave(headerName)"
    @mouseover="onMouseOver(headerName)">
    <div
      :class="[
        'v-data-table-header__wrapper',
        {
          'v-data-table-header__wrapper--custom-column': headerName === 'order',
        },
      ]"
      :style="{'margin-left': renderedDraggableRef ? '0' : '-16px'}">
      <vue-draggable-resizable
        v-if="isAllowedToResize"
        class-name-handle="resizable-handle"
        :w="headerSize"
        :h="48"
        :min-width="headerMinSize"
        :axis="'x'"
        :handles="['mr']"
        :on-resize="onResizeCallback"
        :resizable="canWorkWithResizeColumn && isResizable && !disabledEditMode"
        :active="isResizable"
        :draggable="false"
        class="d-flex align-center resizable-element"
        :class="{
          'table-container__header-cell': activeCellHeader === headerName,
          'table-container__header-cell--hover': getActiveCellsNames.includes(headerName),
          'table-container__header-cell--hover-active': getActiveCellsNames.includes(headerName) && activeCellHeader === headerName,
        }"
        @resizing="onResizeColumn({ width: arguments[2], colName: headerName, name: headerName, })"
        @resizestop="onResizeColumnStop(
          { width: arguments[2],
            colId: columnId, resourceType: 'schedule',
            projectId, resourceId: scheduleId, viewId: selectedView.id, colName: headerName, isCustomSortable: isCustomSortable})">
        <!-- handles prop name -->
        <template #mr>
          <div
            class="resizable-handle-element"
            @mouseover.stop="isCanceledResizable ? setActiveColumn(headerName): null"
            @mouseleave.stop="setHoveredColumn(isCanceledResizable ? { name: headerName } : { })">
            <div
              :class="{ 'resizable-handle-element__selected-border': isActiveResizable }" />
            <div
              v-show="isActiveResizable"
              :style="{height: `${tableHeight - 48}px`}"
              class="resizable-handle-element__selected-border__bottom" />
          </div>
        </template>
        <template
          v-if="isTagOrCustomColumn">
          <TableFilterTags
            :is-free-workspace="isFreeWorkspace"
            :value="headerName" />
          <app-sort-arrows
            v-if="isCustomSortable && !renderedDraggableRef"
            :sort-by="headerName"
            :sorting-data="sortingData"
            :sortable-options="SORTABLE_OPTIONS"
            @mouse-action="$emit('focused-on-actions', true)"
            @mouse-action-end="$emit('focused-on-actions', false)" />
        </template>
        <template v-else>
          <span>
            {{ headerName }}
            <app-sort-arrows
              v-if="isCustomSortable
                && !renderedDraggableRef"
              :sort-by="headerName"
              :sorting-data="sortingData"
              :sortable-options="SORTABLE_OPTIONS"
              @mouse-action="$emit('focused-on-actions', true)"
              @mouse-action-end="$emit('focused-on-actions', false)" />
          </span>
        </template>
      </vue-draggable-resizable>
      <template v-else-if="headerName!=='order'">
        <div
          :class="{
            'table-container__header-cell': activeCellHeader === headerName,
            'table-container__header-cell--hover': getActiveCellsNames.includes(headerName),
            'table-container__header-cell--hover-active': getActiveCellsNames.includes(headerName) && activeCellHeader === headerName,
          }"
          class="d-flex align-center table-header__not-resizable">
          <span>{{ headerName }}</span>
          <app-sort-arrows
            v-if="isCustomSortable
              && !renderedDraggableRef"
            :sort-by="headerName"
            :sorting-data="sortingData"
            :sortable-options="SORTABLE_OPTIONS"
            @mouse-action="$emit('focused-on-actions', true)"
            @mouse-action-end="$emit('focused-on-actions', false)" />
        </div>
      </template>
      <template v-else-if="headerName === 'order'">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              class="pointer"
              color="green"
              v-bind="attrs"
              v-on="on"
              @click="addNewColumn">
              mdi-plus-circle
            </v-icon>
          </template>
          <span>Add New Field</span>
        </v-tooltip>

        <v-dialog
          v-model="newColumnDialog"
          width="40%"
          persistent>
          <v-card>
            <v-card-title>
              <span>Add New Column</span>
              <span class="sourcery__icon-wrapper black">
                <v-icon
                  color="white"
                  size="14"
                  @click="closeNewColumnDialog">
                  mdi-close
                </v-icon>
              </span>
            </v-card-title>
            <v-card-text>
              <v-row class="align-center">
                <v-col cols="3">
                  Column Name:
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="columnName"
                    :error-messages="errorMessage"
                    placeholder="Color Emitance"
                    @input="errorMessage = ''" />
                </v-col>
              </v-row>
              <v-row class="align-center">
                <v-col cols="3">
                  Column Type:
                </v-col>
                <v-col cols="9">
                  <v-radio-group
                    v-model="columnType"
                    row>
                    <v-radio
                      label="Text"
                      value="string" />
                    <v-radio
                      label="Dropdown"
                      value="dropdown" />
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                class="elevation-0"
                outlined
                @click="closeNewColumnDialog">
                Cancel
              </v-btn>

              <v-btn
                :disabled="!columnName"
                class="elevation-0 white--text"
                color="blue"
                @click="saveNewColumn">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <table-header-actions
        v-if="isCustomSortable
          && !renderedDraggableRef
          && !(selectedViewTag && headerName == productHeaders.TAG)"
        :sort-by="headerName"
        :sorting-data="sortingData"
        :default-sort-by="selectedView.sortingField"
        @sort="setViewsSorting({sortingData: sortHelper(selectedViewId, $event.sortBy, $event.sortOrder)})" />
    </div>
  </th>
</template>
<script>
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import ResizableMixin from '@/mixins/ResizableMixin';
import tableSortingMixin from '@/mixins/tableSortingMixin';
import activeCellsMixin from '@/mixins/activeCellsMixin';
import VueDraggableResizable from 'vue-draggable-resizable';
import { hasEditorAccess } from '@/utils';
import TableFilterTags
  from '@/components/ProjectDetails/ProjectDetailsTableSchedule/TableFilterTags';
import AppSortArrows from '@/components/App/AppSortArrows';
import tableHeaderActions
  from '@/components/ProjectDetails/ProjectDetailsTableSchedule/TableHeaderActions';
import {
  mapActions,
  mapGetters, mapState,
} from 'vuex';
import { SORT_TOP } from '@/constants/scheduleViews';
export default {
  name: 'TableHeaderItem',
  components: {
    VueDraggableResizable,
    TableFilterTags,
    AppSortArrows,
    tableHeaderActions,
  },
  mixins: [ResizableMixin, tableSortingMixin, activeCellsMixin],
  props: {
    projectId: {
      type: String,
      default: '',
    },
    renderedDraggableRef: {
      type: Object,
      default: null,
    },
    isFocusedOnActions: {
      type: Boolean,
      default: false,
    },
    isAllowedToResize: {
      type: Boolean,
      default: false,
    },
    isCustomColumn: {
      type: Boolean,
      default: false,
    },
    disabledEditMode: {
      type: Boolean,
      default: false,
    },
    tableHeight: {
      type: [Number],
      default: 0,
    },
    isCustomSortable: {
      type: Boolean,
      default: false,
    },
    columnId: {
      type: String,
      default: '',
    },
    headerName: {
      type: String,
      default: '',
    },
    thClass: {
      type: String,
      default: '',
    },
    headerSize: {
      type: Number,
      default: 0,
    },
    headerMinSize: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    sharedLink: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      columnName: '',
      errorMessage: '',
      newColumnDialog: false,
      columnType: 'string',
    };
  },
  computed: {
    ...mapGetters('Workspace', ['isFreeWorkspace']),
    ...mapState('ScheduleViews', ['selectedViewId']),
    ...mapState('ProjectDetailsTableSchedule', [
      'scheduleId',
      'customFields',
      'defaultFields',
    ]),
    ...mapGetters('ScheduleViews', ['selectedViewTag', 'isSortingTop', 'isSortingBottom', 'selectedView']),
    isTagOrCustomColumn() {
      return this.headerName === 'Tag' || this.isCustomColumn;
    },
  },
  methods: {
    ...mapActions('ProjectDetailsTableSchedule', ['createCustomColumn']),
    ...hasEditorAccess,
    addNewColumn() {
      this.newColumnDialog = true;
    },
    closeNewColumnDialog() {
      this.newColumnDialog = false;
      this.columnName = '';
    },
    onSort() {
      if (this.isActiveResizable) {
        return;
      }
      this.isCustomSortable
      && !this.renderedDraggableRef
        ? this.setViewsSorting({
          sortingData: this.sortHelper(this.selectedViewId, this.sortingData.sortBy !== this.headerName ? this.headerName : this.selectedView.sortingField,
            SORT_TOP),
        })
        : () => {};
    },
    saveNewColumn() {
      const { columnName, projectId, columnType } = this;

      // verify that the column name is not already taken
      const allColumns = [...this.defaultFields, ...this.customFields];
      const isColumnAlreadyTaken = allColumns.some(column => column === columnName);
      if (isColumnAlreadyTaken) {
        this.errorMessage = 'Column name already taken';
        return;
      }

      this.createCustomColumn({
        columnName,
        projectId,
        sharedLink: this.sharedLink,
        columnType,
      });
      this.closeNewColumnDialog();
    },
  },
};
</script>
<style scoped lang="scss">
@import "src/assets/styles/resizableElement.scss";
$accent-color: rgba(#71d6e0, .2);
$accent-color-header: #DEEFF1;
$grey-color: rgba(#757575, .1);
::v-deep {
  // Changes background of header cell
  .table-container__header-cell {
    background: $grey-color;
    &--hover {
      background: $accent-color;
    }
    &--hover-active {
      background: $accent-color-header;
    }
  }
}
.table-header__not-resizable {
  padding-left: 16px;
  height: 100%;
}
</style>
